import axios from 'axios';
import cloneDeep from 'lodash/cloneDeep'

import * as DataFetchHelper from '../helpers/DataFetchHelper';
import Checkers from "../helpers/class/Checkers";

export function getProperties ( params = undefined) {

  if ( params.hasOwnProperty('bedrooms') && Checkers.isValidPositiveNumber(params.bedrooms) ){
    params.sortingField = 'bedrooms'
    params.sortOrder = 'asc'
  }

  if(params==undefined){
    params = []
  }


  return axios.post(
    `/apiloggia`,
    {
      action: '/api/builder/properties/manager/list/data/v2?' + `${DataFetchHelper.EncodeQueryData( params )}`
    }).then( r => {
    return r.data;
  });
}

export async function sendContactForm ( params = {}, data = {}) {
  return axios.post(
    `/apiloggia`,
    {
      action: '/api/lodge/frontend/send-contact-form?' + `${DataFetchHelper.EncodeQueryData( params )}`,
      json: JSON.stringify( data )
    }).then( r => {
    return r.data;
  });
}

export async function sendNewsletterForm ( params = {}, data = {}) {
  return axios.post(
    `/apiloggia`,
    {
      action: '/api/lodge/frontend/send-subscription-form?' + `${DataFetchHelper.EncodeQueryData( params )}`,
      json: JSON.stringify( data )
    }).then( r => {
    return r.data;
  });
}

export function getAllPropertiesForMap ( params = [], allProperties = null) {
  let parameters =  cloneDeep( params );
  if ( allProperties == "1" ) {
    parameters['limit'] = "all";
  }

  return axios.post(
    `/apiloggia`,
    {
      action: '/api/builder/properties/manager/list/data/v2?' + `${DataFetchHelper.EncodeQueryData( parameters )}`
    }).then( r => {
    return r.data.properties;
  });
}

export function getPropertiesFilters ( params = []) {
  return axios.post(
    `/apiloggia`,
    {
      action: '/api/builder/properties/list/filters?' + `${DataFetchHelper.EncodeQueryData( params )}`
    }).then( response => {
    return response.data.filters;
  });
}

export function getCountPropertiesForFilters ( params = [], selected_filters, filters = []) {
  let data = {
    selected_filters: selected_filters,
    filter: filters
  }

  return axios.post(
    `/apiloggia`,
    {
      action: '/api/builder/properties/list/filters/count?' + `${DataFetchHelper.EncodeQueryData( params )}`,
      json: JSON.stringify( data )
    }).then( response => {
    return response.data.filters;
  });
}

export function getPropertyAvailability(propertyId, checkIn, checkOut){
  return axios.post(`/apiloggia`, {
    action: '/api/lodge/bookings/booking_plan?property_ids=' + `${propertyId}` +'&date_from=' + `${checkIn}` +'&date_to=' + `${checkOut}`
  })
}

export async function getPropertyPrice(propertyId, pageId, checkIn, checkOut, adults, kids){
  return axios.post(`/apiloggia`, {
    action: '/api/lodge/enquiries/get-property-price?property_id=' + `${propertyId}` +'&page_id=' + `${pageId}` +'&checkin=' + `${checkIn}` +'&checkout=' + `${checkOut}` +'&adults=' + `${adults}` +'&kids=' + `${kids}`
  }).then( response => {
    return response.data;
  });
}

export async function getPropertyAreas(propertyId, queryString = null){
  let action = '/api/lodge/frontend/get/property/content/areas/' + `${propertyId}`
  action = action.includes('?') ? action + "&" : action + "?"
  action += (queryString!=null ? queryString : "")

  return axios.post(`/apiloggia`, {
    action: action
  }).then( response => {
    return response.data;
  });
}

export async function getPropertyPools(pageId, propertyId, queryString = null){
  let action = '/api/lodge/frontend/get/property/content/pools/' + `${propertyId}`
  action = action.includes('?') ? action + "&" : action + "?"
  action += (queryString!=null ? queryString : "")

  return axios.post(`/apiloggia?page_id=${pageId}`, {
    action: action
  }).then( response => {
    return response.data;
  });
}

export async function getPropertySpaces(pageId, propertyId, queryString = null){
  let action = '/api/lodge/frontend/get/property/content/spaces/' + `${propertyId}`
  action = action.includes('?') ? action + "&" : action + "?"
  action += (queryString!=null ? queryString : "")

  return axios.post(`/apiloggia?page_id=${pageId}`, {
    action:  action
  }).then( response => {
    return response.data;
  });
}

export async function getPropertyBedrooms(pageId, propertyId, queryString = null){
  let action = '/api/lodge/frontend/get/property/content/bedrooms/' + `${propertyId}`
  action = action.includes('?') ? action + "&" : action + "?"
  action += (queryString!=null ? queryString : "")

  return axios.post(`/apiloggia?page_id=${pageId}`, {
    action: action
  }).then( response => {
    return response.data;
  });
}

export async function getPropertyBedroomById(pageId, propertyId, bedroomId,  queryString = null){
  let action = '/api/lodge/frontend/get/property/content/bedrooms/' + `${propertyId}` + '/' + `${bedroomId}`
  action = action.includes('?') ? action + "&" : action + "?"
  action += (queryString!=null ? queryString : "")

  return axios.post(`/apiloggia?page_id=${pageId}`, {
    action: action
  }).then( response => {
    return response.data;
  });
}

///
export function getSearchDefaultOptionsAction ( pageId, slug ) {
  return axios.post(
    `/apiloggia`,
    {
      action: '/api/lodge/frontend/get/search-suggestions/default?page_id=' + `${pageId}`
    }).then( response => {
    let defaultLocationValue = null;
    const options = [];
    let data = response.data;
    data.forEach( ( suggestion ) => {
      options.push({
        label: suggestion.title,
        value: suggestion.id,
        slug: suggestion.slug
      })


      if ( suggestion.slug==window.location.pathname ) {
        defaultLocationValue = {
          value: suggestion.id,
          label: suggestion.title,
          slug: suggestion.slug
        }
      }
    })
    return { options, defaultLocationValue };
  });
}

export function loadSearchOptionsAction ( pageId, inputValue, callback ) {
  axios.post( `/apiloggia`,
    {
      action: `/api/lodge/frontend/get/search-suggestions/all?page_id=` + pageId + "&search=" + inputValue
    }).then (
    response => {
      const options = [];
      let data = response.data;

      data = data.filter( ( i ) =>
        i.title.toLowerCase().includes( inputValue.toLowerCase() )
      );

      data.forEach( ( suggestion ) => {
        options.push({
          label: suggestion.title,
          value: suggestion.id,
          slug: suggestion.slug
        })
      })
      callback( options );
    })
}